exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activation-js": () => import("./../../../src/pages/activation.js" /* webpackChunkName: "component---src-pages-activation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-success-freespin-js": () => import("./../../../src/pages/success-freespin.js" /* webpackChunkName: "component---src-pages-success-freespin-js" */),
  "component---src-templates-category-blog-js": () => import("./../../../src/templates/category/blog.js" /* webpackChunkName: "component---src-templates-category-blog-js" */),
  "component---src-templates-category-promo-js": () => import("./../../../src/templates/category/promo.js" /* webpackChunkName: "component---src-templates-category-promo-js" */),
  "component---src-templates-category-regular-js": () => import("./../../../src/templates/category/regular.js" /* webpackChunkName: "component---src-templates-category-regular-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-page-bingo-js": () => import("./../../../src/templates/page/bingo.js" /* webpackChunkName: "component---src-templates-page-bingo-js" */),
  "component---src-templates-page-live-casino-js": () => import("./../../../src/templates/page/live-casino.js" /* webpackChunkName: "component---src-templates-page-live-casino-js" */),
  "component---src-templates-page-login-js": () => import("./../../../src/templates/page/login.js" /* webpackChunkName: "component---src-templates-page-login-js" */),
  "component---src-templates-page-prediction-js": () => import("./../../../src/templates/page/prediction.js" /* webpackChunkName: "component---src-templates-page-prediction-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-page-regular-js": () => import("./../../../src/templates/page/regular.js" /* webpackChunkName: "component---src-templates-page-regular-js" */),
  "component---src-templates-page-slots-js": () => import("./../../../src/templates/page/slots.js" /* webpackChunkName: "component---src-templates-page-slots-js" */),
  "component---src-templates-page-virtual-game-js": () => import("./../../../src/templates/page/virtual-game.js" /* webpackChunkName: "component---src-templates-page-virtual-game-js" */),
  "component---src-templates-page-virtual-games-list-js": () => import("./../../../src/templates/page/virtual-games-list.js" /* webpackChunkName: "component---src-templates-page-virtual-games-list-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */)
}

